
export default function SingleRecipe({item,handleWantToCook}) {
    const {recipe_name, short_description, ingredients, preparing_time, calories,recipe_id,recipe_image} = item;

  return (
    <>

        <div className="myCard w-80 grow-1">
    <div className="max-w-sm rounded overflow-hidden shadow-lg">
    <img className="w-full" src={recipe_image} alt="Sunset in the mountains"/>
    <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{recipe_name}</div>
        <p className="text-gray-700 text-base">
            {short_description}
        </p>
        <br />
        <hr />
        <br />
        <h2 className="text-xl mb-2 font-semibold">Ingredients: 6</h2>

            <ul style={{listStyle: 'circle'}} className='ml-8'>
                {
                ingredients.map(ele=>(<li>{ele}</li>))
                }
            </ul>
    </div>
    <div className="px-6 pt-4 pb-2">
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"># {preparing_time}</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"># {calories}</span>
    </div>
    <button onClick={() => handleWantToCook(recipe_id)} className='recipe-btn'>Want to Cook</button>
    {/* <button onClick={()=>alert("hi")} className='recipe-btn'>Want to Cook</button> */}
    </div>
</div>
    </>
  )
}
