
export default function Sidebar({wantToCook, wantCookItems,handlePreparing, currentlyCookCount, currentlyCookitems}){

    return(
        <>
            <div className="col-span-2 m-2 border-gray-700">
                            <div className="recipe-right-box text-center border-gray-700">
                                <h2 className='text-2xl font-bold'>Want to cook: {wantToCook}</h2>
                                <br /> <hr /> <br />
                                {
                                    wantCookItems.length ===0 ? "Nothing is here to cook" :(
                                        <div className="title flex justify-around font-semibold px-3">
                                            <h2>Name</h2>
                                            <h2>Time</h2>
                                            <h2>Catagories</h2>
                                            <h2> </h2>
                                        </div>
                                    )
                                }
                                {
                                    // console.log(wantCookItems)
                                }

                                {
                                    wantCookItems.map((ele,i)=>(
                                        <div className="item flex px-3 py-5 justify-between items-center my-5">
                                            <p className="font-bold mr-2">{i+1}</p>
                                            <p className="w-1/3">{ele.recipe_name}</p>
                                            <p className="w-1/3">{ele.preparing_time}</p>
                                            <p className="w-1/3">{ele.calories}</p>
                                            <button onClick={()=>handlePreparing(ele.recipe_id)} className='btn'>Preparing</button>
                                        </div>
                                        // console.log(ele)
                                    ))
                                }
                               
                                <br /><br /><hr />
                                <h2 className="text-3xl font-bold py-5 mt-10">Currently cooking: {currentlyCookCount}</h2>

                                <div className="title flex justify-around font-semibold px-3 my-5">
                                    <h2>Name</h2>
                                    <h2>Time</h2>
                                    <h2>Catagories</h2>
                                </div>

                                {
                                    currentlyCookitems.map((ele,i)=>(
                                    <div className="item mb-2 flex p-3 justify-between py-5">
                                        <p className="font-bold">{i+1}</p>
                                        <p>{ele.recipe_name}</p>
                                        <p>{ele.preparing_time}</p>
                                        <p>{ele.calories}</p>
                                    </div>
                                        
                                    ))
                                }
                                

                           


                                <div className="taka flex justify-end gap-9 pt-5 px-3 font-semibold">
                                    <div>Total Time: <p>45 minutes</p></div>
                                    <div>Total Time: <p>1050 calories</p></div>
                                </div>
                            </div>
                        </div>
        </>
    )
}