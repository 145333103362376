import { MyRecipe } from "./Componenets/MyRecipe";
import Sidebar from "./Componenets/SideBar";
import React, { useEffect, useState } from 'react'
// import for modal
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Recipies(){
    const [items, setItems] = useState([]);
    useEffect(()=>{
        fetch('Recipes.json')
        .then(res=>res.json())
        .then(data=>setItems(data));
    },[]);


    const [wantToCook, setWantToCook] = useState(0);
    const [wantCookItems, setWantCookItems] = useState([]);

    const handleWantToCook = (id)=>{
        
        const val = wantCookItems.find((ele)=>ele.recipe_id===id) ? id : 0;
        if(val){
            // alert("Already");
            toast.error('This product is already Exists!');
        }else{
            setWantToCook(wantToCook+1);
            const currentItems = items.filter(ele=> ele.recipe_id===id);
            setWantCookItems([...wantCookItems,...currentItems]);
        }

        // console.log(wantCookItems);
    }


    // Preparing buttons handler
    const [currentlyCookCount, setCurrentlyCookCount] = useState(0);
    const [currentlyCookitems, setCurrentlyCookitems] = useState([]);


    const handlePreparing = (id)=>{
        setCurrentlyCookCount(currentlyCookCount+1);
        setWantToCook(wantToCook-1);


        // Add items on current Cook
        const currentCook = wantCookItems.filter(ele=> ele.recipe_id===id);
        setCurrentlyCookitems([...currentlyCookitems, ...currentCook]);

        //Removed old items
        const currentItems = wantCookItems.filter(ele=> ele.recipe_id!==id);
        setWantCookItems([...currentItems]);


    }
    


    return(
        <>
         <ToastContainer />
            <section classNacurrentlyCookCount="recipies py-28">
                <div className="container mx-auto">
                    <h2 className="text-5xl mx-auto text-center pt-20">Our Recipes</h2>
                    <p className="w-2/4 mx-auto my-5 text-center">Customer engagement. Integrating customer reviews and special promotions can further enhance the website's appeal and functionality.</p>

            {/* main div  */}

                    <div className="grid grid-cols-5">

                        {/* Recipes div  */}
                        <MyRecipe items={items}  handleWantToCook={handleWantToCook}></MyRecipe>


                        {/* Side bar div  */}
                        <Sidebar currentlyCookitems={currentlyCookitems} currentlyCookCount={currentlyCookCount} handlePreparing={handlePreparing} wantCookItems={wantCookItems} wantToCook={wantToCook}></Sidebar>
                    </div>



                </div>
            </section>

        </>
    )
}