// import img from '../1.jpg';
import SingleRecipe from './SingleRecipe';


export function MyRecipe({items, handleWantToCook}){

 

    return(



        <>



<div className="col-span-3">
   <div className="cardBox flex flex-wrap gap-6 my-10">
                                    
    {
        items.map(item=>(
            <SingleRecipe handleWantToCook={handleWantToCook} item={item}></SingleRecipe>
        ))
    }
 </div>
</div>
        </>
    )
}