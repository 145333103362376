export default function Banner(){
    return (
        <>
        <section className="container mx-auto">
          <div className="banner">
            <h2 className="text-5xl w-3/5 mx-auto leading-20">Discover an exceptional cooking class tailored for you!</h2>
            <p className="w-2/4 mx-auto my-5">Our features include an easy-to-navigate orders, online reservation system, and contact information for seamless customer engagement. Integrating customer reviews and special promotions can further enhance the website's appeal and functionality.</p>
            <div className="btn-group">
                <button className="btn bg-blue">Explore Now</button>
                <button>Our FeedBack</button>
            </div>
          </div>
        </section>
        </>
    )    
}