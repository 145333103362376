// import './App.css';
// import Banner from './Banner';
// import Navbar from './Navbar'


import './App.css';
import { BrowserRouter} from 'react-router-dom';
import Navbar from './Navbar';
import Banner from './Banner';
import Recipies from './Recipies';



function App() {
  return (
    // <React.Fragment>

    //   <Navbar></Navbar>

    //   <Recipies></Recipies>
    //   <h1 className="text-3xl font-bold underline">
    //   Hello world!
    // </h1>
    // </React.Fragment>


    <BrowserRouter>
      <Navbar/>
      <Banner/> 
      <Recipies/>
    </BrowserRouter>
  );
}

export default App;
